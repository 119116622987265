// chakra imports
import {Box, ChakraProvider} from '@chakra-ui/react';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Footer from 'components/Footer/Footer.js';
// core components
import React from 'react';
import {Navigate} from "react-big-calendar";
import {Redirect, Route, Switch} from 'react-router-dom';
import theme from 'theme/theme.js';
import Chat from "views/Chat";
import Home from "views/Home";
import AuthNavbar from "components/Navbars/AuthNavbar";

export default function Pages(props) {
  const {...rest} = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  React.useEffect(() => {
    document.body.style.overflow = 'unset';
    // Specify how to clean up after this effect:
    return function cleanup() {
    };
  });


  return (
    <ChakraProvider theme={theme} resetCss={false} w='100%'>
      <Box w='100%'>
        {/*<AuthNavbar/>*/}
        <Box ref={wrapper} w='100%'>
          <Switch>
            {/*<Route path={'/chat'} component={Chat}/>*/}
            <Route exact={'/'} component={Home}/>
            <Route path={'*'} component={<Navigate to={'/'}/>}/>
          </Switch>
        </Box>
        <Footer/>
      </Box>
    </ChakraProvider>
  );
}
