/*eslint-disable*/
import {Center, Flex, Grid, GridItem, Image, Input, InputGroup, InputRightElement, Link, List, ListItem, Text} from "@chakra-ui/react";
import IconApplePay from "assets/svg/icon-apple-pay.svg";
import IconFacebook from "assets/svg/icon-facebook.svg";
import IconInstagram from "assets/svg/icon-instagram.svg";
import IconMasterCard from "assets/svg/icon-mastercard.svg";
import IconTelegram from "assets/svg/icon-telegram.svg";
import IconTwitter from "assets/svg/icon-twitter.svg";
import IconVisa from "assets/svg/icon-visa.svg";
import React from "react";
import {NavLink} from "react-router-dom";

export default function Footer(props) {
  // const linkTeal = useColorModeValue("teal.400", "red.200");=
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      w={'100%'}
      py={'1'}
      px={{base: '3%', md: '6%', xl: '10%'}}
      color={'white'}
      gap={'5'}
      // pos={'fixed'}
      bottom={0}
      // bg={'gray.50'}
    >
      <Flex
        w={'100%'}
        justifyContent={'space-between'}
        flexDir={{
          base: "column",
          md: "row",
        }}
        gap={4}
      >
        <Flex
          w={{
            base: "100%",
            md: '100%'
          }}
          flexDir={{
            base: "column",
            md: "row",
          }}
          justifyContent={{
            base: "center",
            md: 'space-between',
          }}
          alignItems={{
            base: "center",
            md: 'flex-end',
          }}
          fontSize={{
            base: 'xs',
            md: 'sm'
          }}
        >
          <Flex
            color="gray.500"
          >
            &copy; {new Date().getFullYear()} &nbsp;
            <Text>
              ChatNewsAI LLC - All rights reserved. 
            </Text>
          </Flex>
          <Flex
            color="gray.500"
          >

          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
