import {Box, Button, Flex, Image, Input, InputGroup, InputLeftElement, InputRightElement, Text, Textarea, Tooltip, useQuery} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import {ThreeDots} from "react-loader-spinner";
import {useLocation} from "react-router-dom";
import {parse} from "search-params";
import IconSend from "assets/svg/icon-send.svg";
import { v4 as uuidv4 } from 'uuid';
import {ask} from "services/api";
import Message from "./Message";
import ScrollToBottom, {useScrollToBottom} from "react-scroll-to-bottom";

function Index() {
  const location = useLocation()
  const search = parse(location.search)
  const [thinking, setThinking] = useState(false)
  const [query, setQuery] = useState(search.q || '')
  const [messages, setMessages] = useState([])
  const [sessionId, setSessionId] = useState(uuidv4());
  const inputRef = useRef()
  const scrollToBottom = useScrollToBottom()

  const toMessageObj = (data, author) => {
    return {
      id: Date.now(),
      author: author,
      message: data
    }
  }
  const onPromptKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!event.shiftKey)
        send(query)
    }
  }

  const send = (message) => {
    setThinking(true)
    setQuery('')

    let _messages = [...messages]
    _messages.push(toMessageObj(message, 'user'))
    setMessages(_messages)
    setTimeout(() => {
      ask({message, sessionId})
        .then(({data, ok}) => {
          if (ok) {
            // console.log(data)
            _messages.push(toMessageObj(data.data, 'bot'))
          }
        })
        .catch((e) => {
          console.log('error', e.message)
        })
        .finally(() => {
          setThinking(false)
          setMessages(_messages)
          inputRef?.current.focus()
        })
    }, 1000)
  }

  useEffect(() => {
    setSessionId(uuidv4()); // Generate a new sessionId whenever the component mounts    
    console.log('Component mounted or remounted');
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  return (
    <Box
      w='100%'
      // h={{sm: "initial"}}
      mx='auto'
    >
      <Flex
        w='100%'
        display={'flex'}
        // h={'100%'}
        overflow={'hidden'}
        position={'relative'}
      >
        <Flex
          w="100%"
          // h={'100%'}
          pt={{base: "10%", md: "3%"}}
          pb={{base: "10%", md: "5%"}}
          px={{base: '3%', md: '6%', xl: '10%'}}
          flexDir={"column"}
          justifyContent='space-between'
          alignItems='center'
          position={'relative'}
        >

          <ScrollToBottom
            className={'chat-history'}
            scrollViewClassName={'max-50vh'}
          >
            <Flex
              // h={'75vh'}
              // maxHeight={'50vh'}
              overflowY={'auto'}
              w={'100%'}
              gap={4}
              flexDir={'column'}
              justifyContent={'flex-end'}
              py={'4'}
            >
              {messages.map((msg, id) => (<Message data={msg} key={id}/>))}
              <ThreeDots height={40} width={40} visible={thinking} color={'gray'}/>
            </Flex>
          </ScrollToBottom>
          <Flex
            w={'100%'}
            gap={4}
          >
            <InputGroup>
              <textarea
                ref={inputRef}
                placeholder={`What's the latest news in ...`}
                rows={query?.split('\n').length}
                onKeyDown={onPromptKeyDown}
                onChange={e => setQuery(e.target.value)}
                disabled={thinking}
                style={{
                  padding: "10px 20px",
                  borderRadius: 26,
                  border: "2px solid #0057d9",
                  width: "100%",
                  resize: "none"
                }}
                value={query}
              >
              </textarea>
            </InputGroup>
            <Button
              bg={'primary.500'}
              color={'white'}
              h={'50px'}
              w={'50px'}
              borderRadius={'50%'}
              variant={'outline'}
              onClick={() => send(query)}
              disabled={thinking || !query}
            >
              <Image
                src={IconSend}
                alt={'icon-send'}
              />
            </Button>
          </Flex>
          <Button
            bg={'primary.500'}
            color={'white'}
            h={'50px'}
            w={'100px'}
            borderRadius={'50%'}
            variant={'outline'}
            mt={2}
            onClick={() => send("I'd like to talk about something else.")}
            disabled={thinking}>Restart
          </Button>
        </Flex>

      </Flex>
      <Box
        position={'fixed'}
        top={'10px'}
        zIndex={'920'}
        right={{
          base: '20px',
          md: '10%'
        }}
      > 
      </Box>
    </Box>
  );
}

export default Index;
