import {Box, Flex, Text} from "@chakra-ui/react";

function Message({data}) {
  const {id, message, author} = data || {}
  const isUsers = author === "user"

  return <Flex
    w={'100%'}
    flexDir="column"
    justifyContent={isUsers ? "flex-end" : "flex-start"}
    bg={isUsers ? "gray.200" : "gray.700"}
    borderRadius={'10px'}
    px={'20px'}
    py={'10px'}
  >
    <Box
      textAlign={'justify'}
      style={{ color: isUsers ? "black" : "whitesmoke", fontFamily: "Roboto, sans-serif"}}
    >
      {message}
    </Box>
  </Flex>
}

export default Message
