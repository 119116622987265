import {create} from "apisauce";

// const BackServer = "http://localhost:8080"

// const BackServer = "https://chatnewsai.com"

 const BackServer = "https://chatnewsai.onrender.com"

const API = create({
  baseURL: `${BackServer}/api`,
  headers: {
    Authorization: "00000"
  }
})

export const ask = ({message, sessionId}) => API.post('/ask', {
  sessionId,
  message,
})

