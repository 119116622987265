import {Box, Button, Flex, Image, Input, InputGroup, InputLeftElement, InputRightElement} from "@chakra-ui/react";
import ImageLogo from "assets/img/logo.png";
import IconSend from "assets/svg/icon-send.svg";
import React, {useState} from "react";
import {ThreeDots} from "react-loader-spinner";
import {useHistory} from "react-router-dom";

function Banner() {

  const [query, setQuery] = useState('')
  const history = useHistory()

  const onPromptKeyDown = (event) => {
    if (event.key === 'Enter') {
      start()
    }
  }

  const start = () => {
    if (!query)
      return

    // alert('enter key pressed')
    history.push(`/chat?q=${query}`)
  }

  return <Box
    w='100%'
    display={'block'}
    h={'100%'}
    overflow={'hidden'}
    position={'relative'}
  >
    <Flex
      w="100%"
      h={{sm: "initial"}}
      bg={"white"}
      pt={{base: "10%", md: "0%"}}
      px={{base: '3%', md: '6%', xl: '10%'}}
      flexDir={{base: "column", md: "row"}}
      justifyContent='center'
      alignItems='center'
      position={'relative'}
    >
      <Flex
        px={{base: "0px", md: "5%"}}
        flexWrap={'wrap'}
        w={{base: '100%', md: '60%'}}
        justifyContent={'flex-start'}
        alignItems={'space-between'}
        gap={{
          base: '10',
          md: '160'
        }}
        zIndex={'100'}
      >
        <Flex
          w={'100%'}
          flexDir={'column'}
          alignItems={'center'}
          gap={'10'}
        >
          <Image
            src={ImageLogo}
            w={'90%'}
            alt={'logo'}
            margin={'auto'}
            />
        </Flex>
      </Flex>
    </Flex>
  </Box>
}

export default Banner
