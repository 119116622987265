import {Button, Flex, Heading, Input, Text, Textarea} from "@chakra-ui/react";
import React, {useState} from "react";
import ImageArchitect1 from "../../assets/img/ImageArchitect1.png";
import ImageArchitect2 from "../../assets/img/ImageArchitect2.png";
import ImageArchitect3 from "../../assets/img/ImageArchitect3.png";
import ImageArchitect4 from "../../assets/img/ImageArchitect4.png";
import EmailJS from "@emailjs/browser";
import toast, {Toaster} from "react-hot-toast";

EmailJS.init('Fq7na8CmqHSWY_0j3')

function ContactUs() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [sending, setSending] = useState(false)

  const onSend = () => {
    // window.open(`mailto:info@chatnewsai.com?subject=${encodeURIComponent(name)}&from=${email}&body=${encodeURIComponent(message)}`)
    if (name === '' || email === '' || message === '') {
      toast.error('Please fill all fields')
      return
    }

    if (sending) {
      toast.error('Your submission is being processed. Please wait a moment.')
      return
    }

    setSending(true)
    const loading = toast.loading('Sending message...')
    EmailJS.send('service_rt82bfi', 'template_2sivp5a', {
      to_name: 'Allan Hoving',
      from_name: name,
      message: message,
      reply_to: email,
    })
      .then((result) => {
        console.log(result.text);
        toast.success('Message sent successfully')
      })
      .catch((error) => {
        console.log(error.text);
        toast.error('Message sent failed')
      })
      .finally(() => {
        toast.dismiss(loading)
        setSending(false)
      })
  }
  return <div style={{marginBottom: 50}}>
    <p><center><strong>
      CONTACT US
      </strong>
    </center>
      </p>
      <p>
        <center>
          For more information or to schedule a demo, email info@chatnewsai.com
        </center>
      </p>
  </div>

  
  // return <Flex
  //   flexDir={'column'}
  //   py={'4'}
  //   px={{base: '3%', md: '6%', xl: '10%'}}
  //   w={'100%'}
  //   alignItems={'flex-start'}
  //   justifyContent={'center'}
  //   position={'relative'}
  // >
  //   <Flex
  //     w="100%"
  //     h={{sm: "initial"}}
  //     // pt={{base: "10"}}
  //     flexDir={{base: "column", md: "row"}}
  //     justifyContent='center'
  //     alignItems='center'
  //     position={'relative'}
  //     gap={'2'}
  //   >
  //     <Flex
  //       px={{base: "0px", md: "5%"}}
  //       flexWrap={'wrap'}
  //       w={{base: '100%', md: '50%'}}
  //       justifyContent={'flex-start'}
  //       alignItems={'center'}
  //       gap={'4'}
  //       zIndex={'100'}
  //       flexDir={'column'}
  //     >
  //       <Heading as={'h2'} size={'2xl'}>
  //         Contact Us
  //       </Heading>
  //       <Text color='gray.500'>
  //         Send Message
  //       </Text>
  //       <Flex
  //         w={'100%'}
  //         flexDir={'column'}
  //         justifyContent={'flex-start'}
  //         alignItems={'center'}
  //         gap={'5'}
  //       >
  //         <Input
  //           borderRadius='12px'
  //           bg={'white'}
  //           placeholder={'Name'}
  //           h={'48px'}
  //           value={name}
  //           onChange={e => setName(e.target.value)}
  //         />
  //         <Input
  //           borderRadius='12px'
  //           bg={'white'}
  //           placeholder={'Email*'}
  //           h={'48px'}
  //           type={'email'}
  //           value={email}
  //           onChange={e => setEmail(e.target.value)}
  //         />
  //         <Textarea
  //           borderRadius='12px'
  //           bg={'white'}
  //           placeholder={'Message'}
  //           value={message}
  //           onChange={e => setMessage(e.target.value)}
  //         />
  //         <Button
  //           // bg={'primary.500'}
  //           // color={'white'}
  //           h={'60px'}
  //           w={'170px'}
  //           fontSize={'lg'}
  //           borderRadius={'30px'}
  //           variant={'outline'}
  //           onClick={onSend}
  //         >
  //           Send
  //         </Button>
  //       </Flex>
  //     </Flex>
  //   </Flex>
  //   <Toaster
  //     position={'top-center'}
  //   />
  // </Flex>
}

export default ContactUs

const RESTAURANTS = [
  {
    name: 'Best Burgers',
    icon: ImageArchitect1,
    category: 'Burgers',
    duration: '20-30 min',
    rate: 4.9,
  },
  {
    name: 'Best Pizza',
    icon: ImageArchitect2,
    category: 'Pizza',
    duration: '~45 min',
    rate: 4.3,
  },
  {
    name: 'Sushi',
    icon: ImageArchitect3,
    category: 'Sushi',
    duration: '15-20 min',
    rate: 3.7,
  },
  {
    name: 'Lorem Ipsum',
    icon: ImageArchitect4,
    category: 'Vegetarian',
    duration: '~50 min',
    rate: 4.0,
  },
]
