import {Box,} from "@chakra-ui/react";
import React from "react";
// Assets
import Banner from './Banner'
import ContactUs from "./ContactUs"
import Chat from "../Chat"

function Index() {
  // Chakra color mode

  return (
    <Box
      w='100%'
      mx='auto'
      bg={'white'}
    >
      <Banner/>
      <Chat/>
      <ContactUs/>
    </Box>
  );
}

export default Index;
